.chart-container {
	padding: 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
}

.chart-item {
	flex-basis: 32%;
	box-sizing: border-box;
	padding: 0 10px;
}
