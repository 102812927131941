.filter-communications {
	display: flex;
	align-items: center;
	background-color: var(--color-white);
	margin-bottom: 10px;
	height: 50px;

	&--item {
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		border-right: 1px solid var(--color-light-gray);

		&.no-border {
			border: none;
		}

		.react-datepicker-wrapper {
			flex-grow: 1;
			margin-left: 5px;
		}

		.filter-outbox {
			&>div {
				width: 130px;
			}
		}
	}
}