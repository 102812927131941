.ld-quote-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  header {
    background-color: #e6eff8;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
      color: #13161a;
      font-size: 16px;
      margin: 0;
    }
  }

  footer {
    background-color: #e6eff8;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    background-color: #65B688;
    color: white;
  }

  .btn:hover {
    opacity: 0.8;
  }

  .ld-quote-iframe-wrap {
    width: 100vw;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
  }

  .ld-quote-iframe {
    width: 100%;
    max-width: 960px;
    height: 100%;
  }
}


