.job-communications {
	background-color: var(--color-white);
	&-messages {
		position: relative;
		&-chat {
			position: absolute;
			top: 3%;
			right: 3%;
			display: flex;
			flex-direction: column;
			background-color: var(--color-white);
			button {
				margin-top: 7px;
			}
		}
	}
}

.new-table--row.border-top {
	border-top: 2px solid var(--color-light-gray);
}
