.wrapper_job_banner {
	height: 1035px;
	position: relative;
	width: 100%;
	padding: 10px 0;
	margin-bottom: 4px;
	background-color: var(--color-white);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: cover;
	pointer-events: none;

	.wrap_banner {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		left: 100px;
	}
}
