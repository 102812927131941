.waypoint {
  padding: 0 10px;

	&:before, &:after {
		left: 31.5px!important;
	}
	& > div {
		padding: 20px 5px;
	}
	&__first {
		position: relative;
		&:before {
			content: '..';
			color: var(--color-text-gray);
			transform: rotate(90deg) scale(2.4);
			position: absolute;
			bottom: -3px;
			left: 36.5px;
			z-index: 3;
		}
	}

	&__last {
		position: relative;
		&:after {
			content: '..';
			color: var(--color-text-gray);
			transform: rotate(90deg) scale(2.4);
			position: absolute;
			top: 1px;
			left: 36.5px;
			z-index: 3;
		}
	}

	&__middle {
		position: relative;
		&:before {
			content: '..';
			color: var(--color-text-gray);
			transform: rotate(90deg) scale(2.4);
			position: absolute;
			bottom: -3px;
			left: 36.5px;
			z-index: 3;
		}

		&:after {
			content: '..';
			color: var(--color-text-gray);
			transform: rotate(90deg) scale(2.4);
			position: absolute;
			top: 1px;
			left: 36.5px;
			z-index: 3;
		}
	}
}
