.calendar-events {
  margin: 10px 0;

  .list-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid var(--color-gray);
    box-sizing: border-box;
    text-align: center;
    min-height: 32px;
    font-size: 12px;
    color: var(--color-white);
    font-weight: 700;
    background-color: var(--color-mainblue);
    margin: 0 -25px;

    &:hover {
      pointer-events: none;
    }

    & > div {
      border-right: 1px solid var(--color-gray);

      &:last-child {
        border: none;
      }
    }

    svg {
      path {
        fill: var(--color-white);
      }
    }
  }

  .days-group {
    margin: 0 -25px;

    .day-head {
      width: 100%;

      .open {
      }

      &-date {
        font-weight: 700;
        width: 100%;
        text-align: center;
      }
    }

    .jobs-list {
      max-height: 0;
      overflow: hidden;
      background-color: var(--color-white);

      &.open {
        max-height: 100%;
      }

      .main-job {
        display: flex;
        cursor: pointer;
        border-bottom: 1px solid var(--color-gray);
        padding: 12px 0;
        width: 100%;
        align-items: center;

        &:hover {
          background-color: var(--color-light-gray);
        }

        &:not(:first-child) {
          border-top: 1px solid var(--color-gray);
        }

        p {
          text-align: center;
        }

        .status {
          position: absolute;
          left: 12px;
        }
      }
    }
  }
}
