.dropdown-menu--wrapper {
  position: relative;

  .menu {
    position: absolute;
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 12px;
    border-radius: 2px;
    min-width: 11rem;
    z-index: 10;
    background-color: var(--color-white);
    border: 1px solid rgba(0, 0, 0, .15);
    display: flex;
    flex-direction: column;
    .link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .counter {
        color: var(--color-mainblue);
        background: var(--color-light3);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        line-height: 24px;
        z-index: 0;
        text-align: center;
        font-weight: 700;
      }
    }
    .menu-button {
      width: 60px;
      height: 60px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .counter {
        position: absolute;
        top: 10%;
        right: 10%;
        background-color: var(--color-light-gray);
        border-radius: 50%;
        padding: 1px;
        width: 20px;
        height: 20px;
        color: var(--color-mainblue);

        &.new {
          color: var(--color-white);
          background: var(--color-mainred);
        }
      }

      &.isActive {
        span {
          svg path {
            fill: var(--color-mainblue)
          }
        }
      }
    }

    &.child-option {
      position: absolute;
      left: 100%;
      top: 32px;
    }
    //&:after {
    //	content: "";
    //	position: absolute;
    //	bottom: 100%;
    //	left: 15%;
    //	border-width: 5px;
    //	border-style: solid;
    //	border-color: transparent transparent var(--color-darkblue) transparent;
    //}
  }
}
