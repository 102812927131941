html {
  height: 100%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

button:hover,
button:focus {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Jost', sans-serif;
  background-color: #e6eff8;
  font-size: 12px;
  position: relative;
  min-height: 100%;
}

button,
label {
  background-color: transparent;
  border: none;
  font-weight: 700;
}

label {
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  margin: 0;

  &.file {
    cursor: pointer;
    text-align: center;
    justify-content: center;
    color: var(--color-mainblue);
    font-weight: 700;
    padding: 10px;

    input[type='file'] {
      display: none;
    }
  }

  &.start-value {
    justify-content: flex-start;

    input[type='checkbox'] {
      margin: 0 15px 0 0;
    }
  }
}

.shadow {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03);
  background-color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

input:disabled {
  pointer-events: none;
  color: var(--color-text-gray) !important;

  & + span {
    color: var(--color-text-gray) !important;
  }

  span {
    color: var(--color-text-gray) !important;
  }
}

button.submit,
label.submit {
  font-weight: 700;
  color: var(--color-mainblue);
}

button:disabled {
  opacity: 0.7;
}

.disabled {
  color: var(--color-text-gray) !important;
  pointer-events: none;
}

textarea {
  width: 100%;
  height: 100%;
}

input,
textarea {
  border: 0;
  background-color: var(--color-white);
  color: var(--color-black);
  font-weight: 700;

  &::placeholder {
    font-weight: 700;
    color: var(--color-text-gray);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.link {
  padding: 12px;
  text-align: left !important;
  font-weight: 600;
  width: 100%;
  color: var(--color-black);
  background-color: var(--color-white);
  display: flex;
  align-items: center;

  svg,
  .tooltip-wrapper {
    margin-left: auto;
  }

  &:hover {
    outline: 0;
    color: var(--color-white) !important;
    background-color: var(--color-darkblue) !important;
    box-shadow: 1px 0 0 var(--color-darkblue), -1px 0 0 !important;
  }
}

h3.header {
  padding: 20px;
  color: var(--color-black);
  font-size: 11px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
}

h4.subHeader {
  padding: 12px;
  color: var(--color-black);
  font-size: 11px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  margin: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

input {
  outline: none;
  padding: 5px;

  &:not([type='checkbox']) &:not([type='radio']) {
    &:focus {
      transition: all 0.3s ease-in-out;
      box-shadow: 1px 0 0 0 var(--color-mainblue), 0 1px 0 0 var(--color-mainblue), 1px 1px 0 0 var(--color-mainblue),
        /* Just to fix the corner */ 1px 0 0 0 var(--color-mainblue) inset, 0 1px 0 0 var(--color-mainblue) inset;
    }
  }
}

.react-select-container {
  flex-grow: 1;

  input {
    &:focus {
      box-shadow: none;
    }
  }
}

input[type='checkbox'],
[type='radio'] {
  margin: 0 5px;
}

.mw-auto {
  min-width: auto !important;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.confirmed {
  color: var(--color-light-green);
}

.active {
  svg path {
    fill: var(--color-mainblue);
  }
}

.icon-red {
  svg path {
    fill: var(--color-mainred);
  }
}

.icon-green {
  svg path {
    fill: var(--color-light-green);
  }
}

.icon-yellow {
  svg path {
    fill: var(--color-yellow);
  }
}

.icon-blue {
  svg path {
    fill: var(--color-mainblue);
  }
}

button {
  &.confirm {
    background-color: var(--color-light-green);
    color: var(--color-white);
  }

  &.cancel {
    color: var(--color-white);
    background-color: var(--color-mainred);
  }

  &.button-blue {
    background-color: var(--color-mainblue);
    color: var(--color-white);
    padding: 1rem;
    width: 140px;
  }

  .disabled {
    color: var(--color-light-gray);
  }
}

.page-content {
  flex-grow: 1;
}

// EDIT SWAL BUTTONS
.swal2-container {
  z-index: 15 !important;
}

.swal2-actions {
  z-index: 0;
  justify-content: space-around;

  button {
    min-width: 120px;
    border-radius: 5px;
    padding: 10px;
  }
}

// FLEX SIZES
@mixin fb($size) {
  .fb#{$size} {
    flex-basis: 100% / $size;
    text-align: center;
  }
}

@include fb(1);
@include fb(2);
@include fb(3);
@include fb(4);
@include fb(5);

@mixin w($size) {
  .w#{$size} {
    flex-basis: $size * 1% !important;
  }
}

@include w(0);
@include w(1);
@include w(5);
@include w(10);
@include w(15);
@include w(20);
@include w(25);
@include w(30);
@include w(32);
@include w(35);
@include w(40);
@include w(45);
@include w(49);
@include w(50);
@include w(55);
@include w(60);
@include w(65);
@include w(70);
@include w(75);
@include w(80);
@include w(85);
@include w(90);
@include w(95);
@include w(100);

@mixin mh($size) {
  .mh#{$size} {
    min-height: $size * 1px !important;
    max-height: $size * 1px !important;
  }
}

@include mh(50);
@include mh(55);
@include mh(60);
@include mh(65);
@include mh(70);
@include mh(75);
@include mh(80);
@include mh(100);
@include mh(200);
@include mh(350);

@mixin p($size) {
  .p#{$size} {
    padding: $size * 1px !important;
  }
}

@mixin pb($size) {
  .pb#{$size} {
    padding-bottom: $size * 1px !important;
  }
}

@include p(0);
@include p(1);
@include p(5);
@include p(10);
@include p(15);
@include p(20);

@include pb(20);

.oy-s {
  overflow-y: scroll;
}

// default table
table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  background-color: var(--color-white);

  tr {
    border-top: 1px solid var(--color-light-gray);
    border-bottom: 1px solid var(--color-light-gray);
    text-align: center;
  }

  td {
    padding: 10px 0;
    text-align: center;
  }
}

.error {
  color: var(--color-mainred);
  font-weight: 500;
}

.main {
  color: var(--color-mainblue) !important;
}

.default {
  color: var(--color-text-gray) !important;
  font-weight: 500;
}

.bcg-light {
  background-color: var(--color-light) !important;
}

.bcg-green {
  background-color: var(--color-light-green) !important;
}

.bcg-blue {
  background-color: var(--color-mainblue) !important;
}

.bcg-yellow {
  background-color: var(--color-yellow) !important;
}

.bcg-red {
  background-color: var(--color-mainred) !important;
}

.text-green {
  color: var(--color-light-green) !important;
}

.no-padding {
  padding: 0 !important;
}

.placeholder-date {
  &::placeholder {
    font-weight: 700;
    color: var(--color-text-gray);
  }
}

.text-center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.q-view {
  display: inline-block;
  color: var(--color-mainblue);
  padding: 20px 40px;
  font-weight: 700;
  border-bottom: 2px solid var(--color-mainblue);
}

.active-row {
  background-color: var(--color-mainblue);
  color: var(--color-white);
}

.position-relative {
  position: relative;
}

// INSURANCE  color in job
.insurance-c {
  &.green {
    svg path {
      fill: var(--color-light-green);
    }
  }

  &.yellow {
    svg path {
      fill: var(--color-yellow);
    }
  }

  &.red {
    svg path {
      fill: var(--color-mainred);
    }
  }

  &.dark-red {
    svg path {
      fill: var(--color-dark-red);
    }
  }
}

/// JOB menu tabs
.menu-tab {
  position: relative;
  padding: 16px;
  border-left: 1px solid var(--color-light-gray);
  border-bottom: 2px solid transparent;

  .tooltip-wrapper {
    margin: 0 !important;
  }

  &__selected {
    border-bottom: 2px solid var(--color-mainblue);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: var(--color-white);

    svg path {
      fill: var(--color-mainblue);
    }
  }

  .inventory {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--color-light-gray);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
  }
}

.maps-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;

  .map-item {
    flex-basis: 49%;
    align-self: flex-start;
    margin: 0;

    &-body {
      background-color: var(--color-light);
      display: flex;
      padding-bottom: 15px;
    }

    .map {
      padding: 20px;
      background-color: var(--color-light);
    }
  }
}

.extrastops-wrapper {
  display: flex;
  justify-content: space-between;

  &--item {
    flex-basis: 49%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 0 15px 15px;
    align-self: flex-start;
  }
}

.extrastops-wrapper-additional {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;

  h3 {
    text-align: center !important;
  }

  &--list {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--item {
      flex-basis: 49%;

      & > div {
        margin-bottom: 20px;
      }
    }
  }
}

.waypoint-inputs {
  padding: 7px 15px !important;
}

.agent-form-select {
  position: relative;
  width: 100%;
  font-size: 12px;
  z-index: 2;

  &_label {
    padding: 0 12px 8px 12px;
    text-align: start;
    cursor: pointer;
    color: var(--color-text-gray);
    font-size: 16px;
    font-weight: 700;

    display: flex;
    justify-content: space-between;

    &.selected {
      color: var(--color-black);
    }

    svg {
      path {
        fill: var(--color-dark-gray);
      }
    }
  }

  &_list {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--color-light-gray);
    border-radius: 3px;
    max-height: 300px;
    overflow-y: auto;

    li {
      padding: 8px 12px;
      text-align: start;
      cursor: pointer;

      &:hover {
        background-color: #b2d4ff;
      }

      &.selected {
        background-color: var(--color-mainblue);
      }
    }
  }
}

.table-header-shadow {
  z-index: 1;
  position: relative;
  box-shadow: 0 10px 40px rgba(74, 144, 226, 0.1);
}

.mt-20 {
  margin-top: 20px;
}

.thank-you-page {
  display: flex;
  height: 800px;
}

.thank-you-title {
  font-size: 26px;
  font-weight: 700;
}

.thank-you-text {
  font-size: 22px;
  font-weight: 500;
}

.thank-you-link-back {
  display: inline-block;
  background-color: var(--color-blue);
  color: var(--color-white);
  min-width: 120px;
  padding: 20px;
  border: none;
  border-radius: 5px;
  margin: 20px auto;

  &:hover {
    color: var(--color-white);
    text-decoration: underline;
  }
}

.nowrap {
  white-space: nowrap;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 3;
}

svg .st0 {
  fill: var(--color-dark-gray);
}

.new-table--row--item .react-datepicker__tab-loop {
  width: 0;
}

.menu.assign-dropdown-position {
  right: -75px;
}

.apexcharts-canvas {
  z-index: 1;
}

.inventory-modal {
  display: flex;

  .inventory-categories {
    width: 40%;
  }

  .inventory-total {
    width: 60%;
    height: 600px;

    .tooltip-text {
      left: -52px;
      top: -90%;
    }
  }

  .inventory-items {
    max-height: 360px;
    overflow-y: auto;
  }
}


.swal-popup-width-600 {
  width: 600px;
}