.tooltip-row {
	font-weight: 400;
	position: relative;
	box-sizing: border-box;
	min-height: 25px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 0;
	margin-bottom: 5px;

	span {
		text-align: left;
		padding-left: 10px;
	}
}