.autocomplete-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;

	.location-search-input {
		padding: 0;
	}

	.autocomplete-dropdown-container {
		position: absolute;
		width: 250px;
		z-index: 3;

		.suggestion-item {
			background-color: var(--color-white);
			cursor: pointer;
			padding: 5px;
			border: 1px solid var(--color-text-gray);
			margin-top: 3px;

			&--active {
				background-color: var(--color-light);
				font-weight: 700;
			}
		}
	}
	.error {
		position: absolute;
		bottom: -20px
	}
}
