.av-tooltip {
	min-width: 850px;

	&--row {
		display: flex;

		&--item {
			flex-basis: calc(100% / 3);
			padding: 20px;
			font-weight: 700;
			box-shadow: 1px 0 0 0 var(--color-light-gray),
			0 1px 0 0 var(--color-light-gray),
			1px 1px 0 0 var(--color-light-gray),
			1px 0 0 0 var(--color-light-gray) inset,
			0 1px 0 0 var(--color-light-gray) inset;

			&.text {
				font-weight: 500;
				background-color: var(--color-light2);
			}
		}
	}
}
