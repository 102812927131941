.calendar-wrapper {
	display: flex;
	margin-bottom: 40px;
	background-color: var(--color-white);
	padding: 15px 10px;
	align-items: center;

	&-header {
		margin: 0 50px 0;
		color: var(--color-darkblue)
	}

	.react-datepicker {
		&__input-container {
			input {
				font-size: 14px;
			}
		}

		&__tab-loop {
			z-index: 5;
		}

		.react-datepicker__header {
			font-size: 16px;
		}

		&__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
			font-size: 16px;
			padding: 10px 50px;
			font-weight: 700;
			width: auto;
			margin: 3px;
		}
	}

	.custom-input {
		background-color: var(--color-mainblue);
		color: var(--color-white);
		padding: 6px 18px;
		border-radius: 6px;
		font-size: 16px;
	}
}