.b-chat {
	display: flex;
	width: 100%;
	background-color: var(--color-white);

	&__content {
		width: 100%;
		position: relative;
	}

  &__title-btn {
    padding: 10px;
  }

	.message-input {
		width: 100%;

		border: 1px solid var(--color-light2);
		padding: 10px;
		border-radius: 4px;
	}

	.message-input__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.message-input .btn-send {
		display: block;
		width: 100px;
		height: 34px;
		padding: 6px 12px;
		font-size: 14px;
		line-height: 1.42857;
		color: var(--color-darkblue);
		background-color: var(--color-white);
		background-image: none;
		border: 1px solid var(--color-text-gray);
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
		-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
		transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
		flex: 0 0 auto;
		cursor: pointer;
		font-weight: 600;
	}

	.message-input textarea {
		height: 92px;
		border: 1px solid var(--color-text-gray);
		border-radius: 4px;
		width: 100%;
		margin-right: 10px;
		text-align: left;
		padding: 10px;
		resize: none;
	}

	.message-input input {
		border-radius: 4px;
		margin-bottom: 10px;
		max-width: 40%;
	}

	.message-input textarea::-webkit-input-placeholder {
		color: var(--color-text-gray);
		font-size: 13px;
		font-weight: 400;
	}

	.message-input textarea:-moz-placeholder {
		color: var(--color-text-gray);
		font-size: 13px;
		font-weight: 400;
	}

	.message-input textarea::-moz-placeholder {
		color: var(--color-text-gray);
		font-size: 13px;
		font-weight: 400;
	}

	.message-input textarea:-ms-input-placeholder {
		color: var(--color-text-gray);
		font-size: 13px;
		font-weight: 400;
	}

	.messages {
		max-height: 450px;
		overflow: auto;
		background-color: var(--color-white);
		padding: 0 15px 15px;
		border-radius: 4px;
		margin: 10px;
	}

	.messages__list {
		padding: 30px;
	}

	.messages__item {
		display: flex;
		margin-bottom: 25px;
		position: relative;
		/* flex-direction: column; */
	}

	.messages__item--container {
		display: flex;
		flex-direction: column;
	}

	.messages__time {
		text-align: center;
		font-size: 9px;
		color: var(--color-text-gray);
		width: 100%;
	}

	.messages__text p {
		padding: 10px;
		max-width: 400px;
		margin-bottom: 8px;
		border-radius: 4px;
		color: var(--color-black);
		font-size: 13px;
		font-weight: 400;
		line-height: 1.4;
		letter-spacing: 0.52px;
	}

	.messages__status_change p {
		padding: 10px;
		max-width: 286px;
		margin-bottom: 8px;
		border-radius: 4px;
		color: var(--color-black);
		font-size: 13px;
		font-weight: 400;
		line-height: 1.4;
		letter-spacing: 0.52px;
	}

	.messages__media {
		margin-bottom: 8px;
	}

	.messages-item--replies {
		flex-direction: row-reverse;
		align-items: flex-start;
	}

	.messages-item--replies .messages__text {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: column;
		align-items: stretch;

	}

	.messages-item--replies .messages__text p {
		background-color: var(--color-light);
	}

	.messages-item--replies .messages__text .missed-call {
		background-color: var(--color-light-pink);
	}

	.messages-item--sent .messages__text .missed-call {
		background-color: var(--color-light-pink);
	}

	.messages-item--sent .messages__time {
		/*text-align: right;*/
	}

	.messages-item--sent .message__date {
		text-align: right;
	}

	.messages-item--center {
		height: 50px;
	}

	.messages-item--sent .messages__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.messages-item--sent .messages__text p {
		background-color: var(--color-text-gray);
		margin-left: auto;
	}

	.messages-item audio {
		max-width: 100%;
	}

	.message-timeline-container {
		align-self: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
	}

	.message__ico {
		margin-bottom: 5px;
		top: 0;
	}
}
