.main-preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
	z-index: 9999;
}

.preloader {
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 0;
	border-radius: 100%;
	border: 3px solid var(--color-darkblue);
	border-right-color: var(--color-mainblue);
	border-bottom-color: var(--color-mainblue);
	-webkit-animation: preloader 0.8s linear infinite;
	animation: preloader 0.8s linear infinite;
	z-index: 16;

	&--small {
		width: 15px;
		height: 15px;
	}
}

@keyframes preloader {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes preloader {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}
