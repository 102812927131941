.item-job-stop {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	&--info {
		display: flex;
		align-items: center;
		span {
			margin-right: 10px;
		}
		p {
			font-weight: 700;
			font-size: 12px;
			flex-grow: 1;
		}
	}
	&--map {
		display: flex;
		flex-direction: column;
		&--show{
			padding: 20px;
			flex-grow: 1;
			border: 1px solid var(--color-light2);
			border-radius: 5px;
			margin: 10px 0;
		}
	}
}
