.login-wrapper {
	width: 300px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&--form {
		padding: 15px;
		margin-bottom: 0;
		background-color: var(--color-white);
		border-color: var(--color-light2);
		border-radius: 2px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		box-shadow: none;
		display: flex;
		flex-direction: column;
		align-items: stretch;

		.input-group {
			margin: 20px 0;
			flex-direction: column;

			label {
				font-weight: 700;
			}

			input {
				margin-top: 10px;
				border: 1px solid var(--color-black);
				width: 100%;
				padding: 5px;
			}
		}

		button {
			background-color: var(--color-mainblue);
			padding: 10px;
			color: var(--color-white);
			border-radius: 5px;
		}
	}
}
