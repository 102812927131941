.comm-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 5px;
	flex-grow: 1;
  cursor: pointer;

  &.border {
    box-shadow: 1px 0 0 0 var(--color-light-gray), 0 1px 0 0 var(--color-light-gray), 1px 1px 0 0 var(--color-light-gray), 1px 0 0 0 var(--color-light-gray) inset, 0 1px 0 0 var(--color-light-gray) inset;
  }

	&.active-row {
		* {
			color: var(--color-white) !important;
		}
	}

	&.email_read {
		color: var(--color-darkblue);
		background: var(--color-light4);
		font-weight: 400;
	}

	&--open {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

  span { 
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
