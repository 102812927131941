/* jost-regular - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-400-Book.woff2') format('woff2'),
	url('./assets/fonts/Jost-400-Book.woff') format('woff');
}

/* jost-500 - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-500-Medium.woff2') format('woff2'),
	url('./assets/fonts/Jost-500-Medium.woff') format('woff');
}

/* jost-600 - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Jost'),
	url('./assets/fonts/Jost-600-Semi.woff2') format('woff2'),
	url('./assets/fonts/Jost-600-Semi.woff') format('woff');
}

/* jost-700 - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-700-Bold.woff2') format('woff2'),
	url('./assets/fonts/Jost-700-Bold.woff') format('woff');
}

/* jost-800 - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-800-Hevy.woff2') format('woff2'),
	url('./assets/fonts/Jost-800-Hevy.woff') format('woff');
}

/* jost-900 - latin */
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-900-Black.woff2') format('woff2'),
	url('./assets/fonts/Jost-900-Black.woff') format('woff');
}

/* jost-italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-400-BookItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-400-BookItalic.woff') format('woff');
}

/* jost-500italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-500-MediumItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-500-MediumItalic.woff') format('woff');
}

/* jost-600italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-600-SemiItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-600-SemiItalic.woff') format('woff');
}

/* jost-700italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-700-BoldItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-700-BoldItalic.woff') format('woff');
}

/* jost-800italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-800-HevyItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-800-HevyItalic.woff') format('woff');
}

/* jost-900italic - latin */
@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: local(''),
	url('./assets/fonts/Jost-900-BlackItalic.woff2') format('woff2'),
	url('./assets/fonts/Jost-900-BlackItalic.woff') format('woff');
}
