.reports-table {
  position: relative;
  display: table;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-white);
}

.reports-table-row {
  display: table-row;

  table {
    background-color: unset;
    border-collapse: unset;
  }
}

.reports-table-column {
  display: table-cell;
  color: var(--color-black);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-bottom: 1px solid var(--color-light-gray);
  height: 55px;
  vertical-align: middle;
  text-align: center;
}

.reports-table-column > p {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reports-table-column--2 {
  width: calc(100% / 2);
}

.reports-table-column--4 {
  width: calc(100% / 4);
}

.reports-table-column--5 {
  width: calc(100% / 5);
}

.reports-table-column--6 {
  width: calc(100% / 6);
}

.reports-table-column--7 {
  width: calc(100% / 7);
}

.reports-table-column--8 {
  width: calc(100% / 8);
}

.reports-table-column--9 {
  width: calc(100% / 9);
}

.reports-table-column--10 {
  width: 10%;
}

.reports-table-column--11 {
  width: calc(100% / 11);
}

.reports-table-column--12 {
  width: calc(100% / 12);
}

.reports-table-column--13 {
  width: calc(100% / 13);
}

.reports-table-column--14 {
  width: calc(100% / 14);
}

.reports-table-column--15 {
  width: calc(100% / 15);
}

.reports-table-column--16 {
  width: calc(100% / 16);
}

.reports-table-column--17 {
  width: calc(100% / 17);
}

.reports-table-column--18 {
  width: calc(100% / 18);
}

.reports-table-column--19 {
  width: calc(100% / 19);
}

.reports-table-column--20 {
  width: calc(100% / 20);
}

.reports-table-column--21 {
  width: calc(100% / 21);
}

.reports-table-row .reports-table-column:first-child {
  border-left: none;
}

.reports-table-row:hover {
  //&:first-child {
  & > .reports-table-column {
    background-color: var(--color-mainblue) !important;
    color: var(--color-white);
  }

  //}
}

.reports-table-column--title {
  background-color: var(--color-white);

  &.title-align-left {
    padding-left: 20px;
    text-align: left;
  }
}

// report jobslist
.reports-table-info--title {
  background: #f3f3f3;
}

/// claims dashboard

.reports-table-title {
  border-bottom: 1px solid #e9e9e9;
  display: table-caption;
  text-align: center;
  background-color: #bad9f3;
  font-size: 14px;
  font-weight: normal;
  color: #565f73;
}

.reports-select {
  background: var(--color-mainblue);

  * {
    background: var(--color-mainblue);
    color: var(--color-white);
  }
}

.reports-info {
  margin-bottom: 10px;

  &:first-child {
    background: var(--color-light);
  }
}

.reports-general-info {
  width: 100%;
  font-size: 11px;
  padding: 2px 5px;

  & > tbody > tr {
    border-bottom: 1px solid var(--color-light-gray);
    vertical-align: middle;
    height: 40px;
    font-weight: 500;
  }

  & > tbody > tr:last-child {
    border-bottom: 0;
  }
}
