:root {
  --color-white: #fff;
  --color-black: #000;
  --color-darkblue: #3a4b55;
  --color-mainblue: #3491ed;
  --color-dark-red: #ce1a2c;
  --color-mainred: #f66977;
  --color-gray: #ccc;
  --color-text-gray: #b1c3cc;
  --color-light-gray: #f0f1f2;
  --color-dark-gray: #8d989e;
  --color-light-green: #afdb80;
  --color-green: #28a745;
  --color-purple: #5a5a79;
  --color-light: #fafafa;
  --color-light2: #e5e5e5;
  --color-light3: #f9f9f9;
  --color-light4: #f7f7f7;
  --color-yellow: #ffbf34;
  --color-light-pink: #fea2a0;
}

@import "assets/styles/bootstrap-grid.min.css";
@import "assets/styles/bootstrap-reset.css";
@import "assets/styles/pagination";
@import "assets/styles/preloader";
@import "assets/styles/tooltip";
@import "assets/styles/waypoints";
@import "assets/styles/sweetalert2.min.css";

@import "assets/styles/react-datepicker.min.css";
@import "assets/styles/styles.scss";
@import "assets/styles/report-table";
@import "assets/styles/new-table";
@import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";