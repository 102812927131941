.tooltip-wrapper--title {
	position: relative;
	display: inline-block;
	cursor: help;


	/* Show the tooltip text when you mouse over the tooltip container */
	&:hover .tooltip-text--title {
		visibility: visible;
		opacity: 1;
	}

	/* Tooltip text */
	.tooltip-text--title {
		visibility: hidden;
		white-space: pre-wrap;
		background-color: var(--color-darkblue);
		color: var(--color-white);
		text-align: center;
		padding: 5px;
		border-radius: 6px;
		font-size: 13px;
		min-width: 170px;

		/* Position the tooltip text */
		position: absolute;
		z-index: 5;
		//top: 150%;
		left: -50%;

		/* Fade in tooltip */
		opacity: 0;
		transition: opacity 0.2s;

		/* Tooltip arrow */
		//&:after {
		//	content: "";
		//	position: absolute;
		//	bottom: 100%;
		//	left: 15%;
		//	border-width: 5px;
		//	border-style: solid;
		//	border-color: transparent transparent var(--color-darkblue) transparent;
		//}
	}
}
