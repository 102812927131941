.rbc-calendar {
  min-height: 90vh;
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 5px;

  .rbc-toolbar {
    &-label {
      font-size: 18px !important;
      font-weight: 700 !important;
    }

    button {
      border: none !important;
      font-weight: 700 !important;
      color: var(--color-darkblue);

      &:hover {
        background-color: var(--color-mainblue);
        color: var(--color-white);
      }
    }
  }

  .rbc-timeslot-group {
    .rbc-time-slot {
      min-height: 23.5px;
    }
  }

  .rbc-month-row {
    .description {
      &-desc {
        display: none;
      }

      &-icon {
        display: none !important;
      }
    }
  }

  .description {
    &-title {
      font-weight: 700;
    }

    &-desc {
    }
  }
}
