.navbar {
	z-index: 10;
	background-color: var(--color-white);
	display: flex;
	justify-content: space-between;
	padding: 0;

	&-menu {
		display: flex;
		box-shadow: 1px 0 0 0 var(--color-light-gray),
		1px 0 0 0 var(--color-light-gray) inset;

		&--item {
			list-style: none;
			margin: 0 0.5px;
			box-shadow: 1px 0 0 0 var(--color-light-gray),
			1px 0 0 0 var(--color-light-gray) inset;

			&.reports {
				.link {
					.tooltip-wrapper {
						margin-right: auto;
					}
				}
			}

			//TODO remove menu-button ?!
			.menu-button {
				width: 60px;
				height: 60px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				.counter {
					position: absolute;
					top: 10%;
					right: 10%;
					background-color: var(--color-light-gray);
					border-radius: 50%;
					padding: 1px;
					width: 20px;
					height: 20px;
					color: var(--color-mainblue);

					&.new {
						color: var(--color-white);
						background: var(--color-mainred);
					}
				}

				&.isActive {
					span {
						svg path {
							fill: var(--color-mainblue)
						}
					}
				}
			}

			.menu-list {
				display: flex;
				flex-direction: column;
				list-style: none;
				//TODO remove ?!
				.link {
					justify-content: space-between;
					position: relative;
					align-items: center;
				}

				&__with-buttons {
					.link--submenu {
						margin-left: auto;
						display: flex;
						align-items: center;
						flex-basis: 40%;
						justify-content: space-between;

						& > * {
							cursor: pointer;
						}
					}
				}
			}

			.counter {
				//color: var(--color-mainblue);
				//background: var(--color-light3);
				//width: 24px;
				//height: 24px;
				//border-radius: 50%;
				//line-height: 24px;
				//z-index: 0;
				//text-align: center;
				//font-weight: 700;
			}
		}
	}

	&-header {
		display: flex;
		align-items: stretch;
		flex-basis: 270px;

		.image-cropper {
			width: 30px;
			height: 30px;
			position: relative;
			display: block;
			overflow: hidden;
			border-radius: 50%;
			box-sizing: border-box;
			padding: 0;
		}

		&--logout {
			margin-left: auto;
			border-left: 1px solid var(--color-light3);
			width: 60px;
			height: 60px;
		}

		&--username {
			display: flex;
			align-items: center;
			flex-grow: 1;
			justify-content: center;

			&--name {
				padding-left: 12px;
				text-overflow: ellipsis;
				font-size: 15px;
				font-weight: 700;
			}
		}
	}
}
