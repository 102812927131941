.react-datepicker__time-container {
	.react-datepicker__time {
		.react-datepicker__time-box {
			ul.react-datepicker__time-list {
				li.react-datepicker__time-list-item {
					font-weight: 600;

					&--disabled {
						display: none;
					}
				}
			}
		}
	}
}
