.box-shadow {
  box-shadow: 1px 0 0 0 var(--color-light-gray), 0 1px 0 0 var(--color-light-gray), 1px 1px 0 0 var(--color-light-gray),
    /* Just to fix the corner */ 1px 0 0 0 var(--color-light-gray) inset, 0 1px 0 0 var(--color-light-gray) inset;
}

.new-table {
  background-color: var(--color-white);

  &.modal {
    .new-table--row {
      padding: 0 1px;
    }
  }

  &.mb-20 {
    margin-bottom: 20px;
  }

  &.mt-20 {
    margin-top: 20px;
  }

  &--row {
    display: flex;
    align-items: stretch;

    &.no-padding {
      padding: 0;
    }

    .new-table--row {
      flex-grow: 1;
      flex-basis: 50%;
      padding: 0;
    }

    &__column {
      flex-direction: column;
    }

    &.select {
      padding: 10px;
    }

    &--item {
      box-shadow: 1px 0 0 0 var(--color-light-gray), 0 1px 0 0 var(--color-light-gray), 1px 1px 0 0 var(--color-light-gray),
        1px 0 0 0 var(--color-light-gray) inset, 0 1px 0 0 var(--color-light-gray) inset;
      flex-basis: 50%;
      flex-grow: 1;
      padding: 20px;
      align-items: center;
      display: flex;

      &.no-side-shadows {
        box-shadow: 0 1px 0 0 var(--color-light-gray), 0 1px 0 0 var(--color-light-gray) inset;
      }

      &.no-y-shadows {
        box-shadow: 1px 0 0 0 var(--color-light-gray), 1px 0 0 0 var(--color-light-gray) inset;
      }

      &__column {
        flex-direction: column;
        align-items: stretch;
      }

      &.no-shadow {
        box-shadow: none;
      }

      &.height50 {
        height: 50px;
      }

      .tooltip-wrapper {
        margin-right: 7px;
      }

      input:not([type='checkbox']):not([type='radio']) {
        width: 100%;
        height: 100%;
        border: 0;
        font-size: 11px;
      }

      &.no-padding {
        padding: 0;
      }

      &.no-padding-y {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.no-padding-x {
        padding-left: 0;
        padding-right: 0;
      }

      &.dark {
        background-color: var(--color-light2);
      }

      &.light {
        background-color: var(--color-light);
      }

      & > div {
        width: 100%;
      }

      &.around {
        justify-content: space-around;
      }

      &.select {
        padding: 10px 20px;
      }

      &.item-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .item-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    &.row-border-bottom {
      border-bottom: 1px solid var(--color-light-gray);
    }
  }
}
