.tooltip-wrapper {
	position: relative;
	display: inline-block;
	overflow: initial !important;

	/* Show the tooltip text when you mouse over the tooltip container */
	&:hover .tooltip-text {
		visibility: visible;
		opacity: 1;
	}

	/* Tooltip text */
	.tooltip-text {
		visibility: hidden;
		white-space: nowrap;
		background-color: var(--color-darkblue);
		color: var(--color-white);
		text-align: center;
		padding: 5px;
		border-radius: 6px;
		font-size: 13px;

		/* Position the tooltip text */
		position: absolute;
		z-index: 5;
		top: 150%;
		left: 50%;
		transform: translateX(-50%);
		/* Fade in tooltip */
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&.tooltip-right .tooltip-text {
		left: unset;
		right: 0;
		top: 170%;
		transform: none;
	}

	&.tooltip-left .tooltip-text {
		left: 100%;
		top: 170%;
		right: unset;
	}

	&.icon-col-red {
		svg path {
			fill: red;
		}
	}
}