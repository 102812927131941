.dropdown-wrapper {
	position: relative;

	.menu {
		position: absolute;
		margin: 10px 0 0 0;
		padding: 0;
		font-size: 12px;
		border-radius: 2px;
		min-width: 11rem;
		z-index: 10;
		list-style: none;
		background-color: var(--color-white);
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, .15);
		.link {
			svg, .tooltip-wrapper {
				margin-left: unset;
				margin-right: 7px;
			}
		}
	}

	.horizontal {
		display: flex;
		flex-direction: row;
	}
}
