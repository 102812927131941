.pagination {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding: 20px 0;
	background-color: var(--color-white);

	.previous {
		margin-right: 15px;
	}

	.next {
		margin-left: 15px;
	}

	li {
		a {
			margin: 0 5px;
			color: var(--color-black);
			font-size: 14px;
			border-radius: 50%;
			font-weight: 700;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:not(.disabled):hover {
			a {
				background-color: var(--color-mainblue);
				color: var(--color-white);
			}
		}

		&.previous a, &.next a {
			padding: 5px 7px;
			color: var(--color-black);
			font-size: 12px;
			border-radius: 8px;
			font-weight: 500;
			width: auto;
			height: auto;
			display: block;
		}

		&.disabled {
			a {
				color: var(--color-darkblue);
				pointer-events: none;
			}
		}

		&.active {
			a {
				background-color: var(--color-mainblue);
				color: var(--color-white);
			}
		}
	}
}
