.page-container {
	display: flex;
	padding-top: 20px;
  min-width: 1900px;

	main {
		display: flex;
		flex-grow: 1;
	}
}
