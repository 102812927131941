.container-packing * {
  color: #13161A;
  font-size: 11px;
  line-height: 20px;
}

.container-packing {
  padding: 0 !important;
  min-width: 768px;

  input {
    background-color: inherit;
  }

  h1 {
    text-align: left;
    font-weight: 700;
    padding: 20px;
    text-transform: uppercase;
    color: #252B33;
    margin: 0;
  }
}

.container-packing {
  .fw-700 {
    font-weight: 700;
  }

  .show-more {
    justify-content: center;
    width: 100%;
    font-weight: 700;
    color: #B1C3CC;
  }

  .buttons {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background-color: transparent !important;

    button {
      background-color: var(--color-white);
      font-weight: 700;
      padding: 20px;
      flex: 1 1 auto;
      width: 50%;
    }

    .cancel {
      color: var(--color-text-gray);
      background-color: var(--color-white);
    }

    .confirm {
      color: var(--color-white);
      background-color: var(--color-light-green);

      &:disabled {
        background-color: var(--color-text-gray);
        opacity: 0.7;
        user-select: none;
        cursor: auto;
      }
    }

    .stripped {
      overflow: hidden;
      transition: all 300ms ease;
      max-height: 180px;

      &.open {
        max-height: 100%;
      }

      .new-table--row:nth-child(even) {
        background-color: var(--color-light);
      }
    }
  }
}

.swal2-popup.tooltip-modal-packing {
  width: 15em;
}

.swal2-popup.tooltip-modal-packing .swal2-title {
  font-size: 14px;
}