.elevator-reservation {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    max-width: 500px;
    width: fit-content;
    height: fit-content;
    padding: 20px;
  }

  h1 {
    font-size: 24px;
    color: var(--color-black);
  }

  &__job-title {
    margin-bottom: 25px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-black);
  }

  &__date,
  &__time {
    font-size: 20px;
    color: #819097;
    font-weight: 600;
    text-align: center;

    span {
      margin-left: 10px;
      color: var(--color-black);
      font-weight: 400;
    }
  }

  &__time {
    display: flex;
    justify-content: center;

    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .btn {
      max-width: 200px;
      width: 45%;
      height: 50px;
      font-size: 14px;
      margin: 0 5px;

      &-confirm {
        background-color: var(--color-light-green);
        color: var(--color-white);
      }
      &-reject {
        background-color: var(--color-mainred);
        color: var(--color-white);
      }
    }
  }
}
